<template>
  <div>

    <div class="vx-card" style="overflow: hidden">
      <div class="vx-card__collapsible-content">
        <div class="vx-card__body p-0">

          <gmap-map ref="map" :center="center" :zoom="15" style="width: 100%; height: 500px">
            <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen"
                              @closeclick="infoWinOpen=false">

            </gmap-info-window>
            <DirectionsRenderer/>
          </gmap-map>

        </div>
      </div>
    </div>

    <div class="vx-card mt-5">
      <div class="vx-card__header">
        <div class="vx-card__title">
          <h4 class="w-full"><img class="markerIcon float-left" alt=""
                                  src="data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20width%3D%2227px%22%20height%3D%2243px%22%20viewBox%3D%220%200%2027%2043%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%3Cdefs%3E%0A%3Cpath%20id%3D%22a%22%20d%3D%22m12.5%200c-6.9039%200-12.5%205.5961-12.5%2012.5%200%201.8859%200.54297%203.7461%201.4414%205.4617%203.425%206.6156%2010.216%2013.566%2010.216%2022.195%200%200.46562%200.37734%200.84297%200.84297%200.84297s0.84297-0.37734%200.84297-0.84297c0-8.6289%206.7906-15.58%2010.216-22.195%200.89844-1.7156%201.4414-3.5758%201.4414-5.4617%200-6.9039-5.5961-12.5-12.5-12.5z%22%2F%3E%0A%3C%2Fdefs%3E%0A%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%3Cg%20transform%3D%22translate(1%201)%22%3E%0A%3Cuse%20fill%3D%22%23EA4335%22%20fill-rule%3D%22evenodd%22%20xlink%3Ahref%3D%22%23a%22%2F%3E%0A%3Cpath%20d%3D%22m12.5-0.5c7.18%200%2013%205.82%2013%2013%200%201.8995-0.52398%203.8328-1.4974%205.6916-0.91575%201.7688-1.0177%201.9307-4.169%206.7789-4.2579%206.5508-5.9907%2010.447-5.9907%2015.187%200%200.74177-0.6012%201.343-1.343%201.343s-1.343-0.6012-1.343-1.343c0-4.7396-1.7327-8.6358-5.9907-15.187-3.1512-4.8482-3.2532-5.01-4.1679-6.7768-0.97449-1.8608-1.4985-3.7942-1.4985-5.6937%200-7.18%205.82-13%2013-13z%22%20stroke%3D%22%23fff%22%2F%3E%0A%3C%2Fg%3E%0A%3Ctext%20text-anchor%3D%22middle%22%20dy%3D%220.3em%22%20x%3D%2214%22%20y%3D%2215%22%20font-family%3D%22Roboto%2C%20Arial%2C%20sans-serif%22%20font-size%3D%2216px%22%20fill%3D%22%23FFF%22%3EA%3C%2Ftext%3E%0A%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A"
                                  draggable="false" usemap="#gmimap2" style="width: 27px; height: 43px;">
            <span class="pl-4 pt-4 float-left">Mesto odhoda z JPP</span></h4>
          <div class="clearfix"></div>

        </div>
      </div>
      <div class="vx-card__collapsible-content">
        <div class="vx-card__body">

          <div class="grid grid-cols-1">
            <label for="departureAddress" class="vs-input--label">Naslov mesta odhoda</label>

            <gmap-autocomplete
              ref="departureAddress"
              id="departureAddress"
              @place_changed="(data) => { getAddressData(data, 'departure') }"
              placeholder="začni vpisovati, nato izberi naslov iz ponujenih možnosti"
              :value="publicTransport.departure.quickSearch"
              :options="{componentRestrictions: { country: 'si' }}"
              :select-first-on-enter="true"
              class="vs-inputx vs-input--input normal"></gmap-autocomplete>
          </div>

          <div class="grid grid-cols-1 mt-5">
            <vs-input class="w-full" label="Naziv mesta odhoda (ime naselja, vasi oz. ustanove)"
                      v-model="publicTransport.departure.addressName"/>
          </div>

          <vs-collapse ref="collapsible" class="mt-5">

            <vs-collapse-item class="addressCollapse">
              <div slot="header" class="p-0 text-primary">
                Podrobnosti naslova mesta odhoda
              </div>

              <div class="collapseBody mt-5">

                <div class="flex mb-4">
                  <div class="w-3/4">
                    <vs-input class="w-full" label="Ulica" v-model="publicTransport.departure.route"/>
                  </div>
                  <div class="w-1/4 ml-5">
                    <vs-input class="w-full" label="Hišna številka"
                              v-model="publicTransport.departure.streetNumber"/>
                  </div>
                </div>
                <div class="flex mb-4">
                  <div class="w-3/4">
                    <vs-input class="w-full" label="Naziv pošte"
                              v-model="publicTransport.departure.postalTown"/>
                  </div>
                  <div class="w-1/4 ml-5">
                    <vs-input class="w-full" label="Poštna številka"
                              v-model="publicTransport.departure.postalCode"/>
                  </div>
                </div>
                <div class="flex mb-4">
                  <div class="w-3/4">
                    <vs-input class="w-full" label="Občina" v-model="publicTransport.departure.municipality"/>
                  </div>
                  <div class="w-1/4 ml-5">
                    <vs-input class="w-full" label="Država" v-model="publicTransport.departure.country"/>
                  </div>
                </div>
              </div>
            </vs-collapse-item>
          </vs-collapse>
        </div>
      </div>
    </div>

    <div class="vx-card mt-5">
      <div class="vx-card__header">
        <div class="vx-card__title">
          <h4 class="w-full"><img class="markerIcon float-left" alt=""
                                  src="data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20width%3D%2227px%22%20height%3D%2243px%22%20viewBox%3D%220%200%2027%2043%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%3Cdefs%3E%0A%3Cpath%20id%3D%22a%22%20d%3D%22m12.5%200c-6.9039%200-12.5%205.5961-12.5%2012.5%200%201.8859%200.54297%203.7461%201.4414%205.4617%203.425%206.6156%2010.216%2013.566%2010.216%2022.195%200%200.46562%200.37734%200.84297%200.84297%200.84297s0.84297-0.37734%200.84297-0.84297c0-8.6289%206.7906-15.58%2010.216-22.195%200.89844-1.7156%201.4414-3.5758%201.4414-5.4617%200-6.9039-5.5961-12.5-12.5-12.5z%22%2F%3E%0A%3C%2Fdefs%3E%0A%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%3Cg%20transform%3D%22translate(1%201)%22%3E%0A%3Cuse%20fill%3D%22%23EA4335%22%20fill-rule%3D%22evenodd%22%20xlink%3Ahref%3D%22%23a%22%2F%3E%0A%3Cpath%20d%3D%22m12.5-0.5c7.18%200%2013%205.82%2013%2013%200%201.8995-0.52398%203.8328-1.4974%205.6916-0.91575%201.7688-1.0177%201.9307-4.169%206.7789-4.2579%206.5508-5.9907%2010.447-5.9907%2015.187%200%200.74177-0.6012%201.343-1.343%201.343s-1.343-0.6012-1.343-1.343c0-4.7396-1.7327-8.6358-5.9907-15.187-3.1512-4.8482-3.2532-5.01-4.1679-6.7768-0.97449-1.8608-1.4985-3.7942-1.4985-5.6937%200-7.18%205.82-13%2013-13z%22%20stroke%3D%22%23fff%22%2F%3E%0A%3C%2Fg%3E%0A%3Ctext%20text-anchor%3D%22middle%22%20dy%3D%220.3em%22%20x%3D%2214%22%20y%3D%2215%22%20font-family%3D%22Roboto%2C%20Arial%2C%20sans-serif%22%20font-size%3D%2216px%22%20fill%3D%22%23FFF%22%3EB%3C%2Ftext%3E%0A%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A"
                                  draggable="false" usemap="#gmimap2" style="width: 27px; height: 43px;">
            <span class="pl-4 pt-4 float-left">Mesto prihoda z JPP</span></h4>
        </div>
      </div>
      <div class="vx-card__collapsible-content">
        <div class="vx-card__body">

          <div class="grid grid-cols-1">
            <label for="destinationAddress" class="vs-input--label">Naslov mesta prihoda</label>

            <gmap-autocomplete
              ref="destinationAddress"
              id="destinationAddress"
              @place_changed="(data) => { getAddressData(data, 'destination') }"
              placeholder="začni vpisovati, nato izberi naslov iz ponujenih možnosti"
              :value="publicTransport.destination.quickSearch"
              :options="{componentRestrictions: { country: 'si' }}"
              :select-first-on-enter="true"
              class="vs-inputx vs-input--input normal"></gmap-autocomplete>

          </div>

          <div class="grid grid-cols-1 mt-5">
            <vs-input class="w-full" label="Naziv mesta destinacije (ime naselja, vasi oz. ustanove)"
                      v-model="publicTransport.destination.addressName"/>
          </div>

          <vs-collapse ref="collapsible" class="mt-5">

            <vs-collapse-item class="addressCollapse">
              <div slot="header" class="p-0 text-primary">
                Podrobnosti naslova mesta prihoda
              </div>

              <div class="collapseBody mt-5">
                <div class="flex mb-4">
                  <div class="w-3/4">
                    <vs-input class="w-full" label="Ulica" v-model="publicTransport.destination.route"/>
                  </div>
                  <div class="w-1/4 ml-5">
                    <vs-input class="w-full" label="Hišna številka"
                              v-model="publicTransport.destination.streetNumber"/>
                  </div>
                </div>
                <div class="flex mb-4">
                  <div class="w-3/4">
                    <vs-input class="w-full" label="Naziv pošte"
                              v-model="publicTransport.destination.postalTown"/>
                  </div>
                  <div class="w-1/4 ml-5">
                    <vs-input class="w-full" label="Poštna številka"
                              v-model="publicTransport.destination.postalCode"/>
                  </div>
                </div>
                <div class="flex mb-4">
                  <div class="w-3/4">
                    <vs-input class="w-full" label="Občina"
                              v-model="publicTransport.destination.municipality"/>
                  </div>
                  <div class="w-1/4 ml-5">
                    <vs-input class="w-full" label="Država" v-model="publicTransport.destination.country"/>
                  </div>
                </div>
              </div>
            </vs-collapse-item>
          </vs-collapse>
        </div>
      </div>
    </div>

    <div>Origin: <b v-if="origin && origin.quickSearch">{{ origin.quickSearch }}</b></div>
    <div>Destination: <b v-if="destination && destination.quickSearch">{{ destination.quickSearch }}</b></div>
    <div>Departure time: <b v-if="departureTime">{{ departureTime }}</b></div>
    <div>Arrival time: <b v-if="arrivalTime">{{ arrivalTime }}</b></div>
    <br/>
    <vs-button color="primary" icon="departure_board" @click="getTrafficData" class="my-10 w-full">Pridobi podatke o
      JPP
    </vs-button>


    <div v-if="response && response.routes.length === 0">
      <vs-alert color="danger" icon-pack="feather" icon="icon-info">
        <span>Ni zadetkov! Poskusite iskati z drugačnimi podatki o vstopnih/izstopnih postajah oz. nastavite drugačne čase odhodov/prihodov.</span>
      </vs-alert>
    </div>
    <div v-if="response && response.routes.length > 0">
      <div v-for="(route, routeIndex) in response.routes" :key="'routeItem_' + routeIndex">
        <!--      {{route}}-->

        <vs-collapse ref="collapsible" class="mt-5">

          <vs-collapse-item class="legsCollapse" v-for="(leg, legIndex) in route.legs" v-if="route.legs"
                            :key="'legItem_' + legIndex">
            <div slot="header" class="p-0 text-primary">
              <div>
                <vs-radio v-model="routeOption" :vs-value="routeIndex">Opcija potovanja {{ routeIndex + 1 }}</vs-radio>
              </div>
              <small>
                <vs-list>
                  <vs-list-item :title="leg.steps.length.toString() + ' vmesnih korakov'"
                                subtitle="Število vmesnih korakov potovanja"></vs-list-item>
                  <vs-list-item :title="leg.duration.text" subtitle="Čas potovanja"></vs-list-item>
                  <vs-list-item :title="leg.distance.text" subtitle="Dolžina potovanja"></vs-list-item>
                  <vs-list-item :title="leg.departure_time.text" subtitle="Čas odhoda"></vs-list-item>
                  <vs-list-item :title="leg.arrival_time.text" subtitle="Čas prihoda"></vs-list-item>
                </vs-list>
              </small>
            </div>


            <div class="collapseBody mt-5">
              <div v-for="(step, stepIndex) in leg.steps" v-if="leg.steps" :key="'stepItem_' + stepIndex" class="mb-10">
                <h3>
                  <vs-checkbox v-if="Object.keys(routeInstructions).length > 0"
                               v-model="selectedSteps['step_' + stepIndex + '_route_' + routeIndex]">Korak
                    {{ stepIndex + 1 }} <!-- - {{ 'step_' + stepIndex + '_route_' + routeIndex }} -->
                  </vs-checkbox>
                  <span v-if="Object.keys(routeInstructions).length === 0">Korak {{ stepIndex + 1 }}</span>
                </h3>


                <h4 class="text-success">{{ step.html_instructions }} <small>({{ step.travel_mode }})</small></h4>
                <!--                {{step}}-->

                <div v-if="step.transit_details">vstop:

                  <vs-dropdown>
                    <a class="a-icon" href="#">
                      {{ step.transit_details.departure_stop.name }}
                      <vs-icon class="" icon="expand_more"></vs-icon>
                    </a>
                    <vs-dropdown-menu>
                      <vs-dropdown-item @click="setAsAddressPointA(step.transit_details.departure_stop)">
                        Nastavi naslov kot točko A
                      </vs-dropdown-item>
                      <vs-dropdown-item @click="setAsAddressPointB(step.transit_details.departure_stop)">
                        Nastavi naslov kot točko B
                      </vs-dropdown-item>
                    </vs-dropdown-menu>
                  </vs-dropdown>

                  ob {{ step.transit_details.departure_time.text }} -> izstop:

                  <vs-dropdown>
                    <a class="a-icon" href="#">
                      {{ step.transit_details.arrival_stop.name }}
                      <vs-icon class="" icon="expand_more"></vs-icon>
                    </a>
                    <vs-dropdown-menu>
                      <vs-dropdown-item @click="setAsAddressPointA(step.transit_details.arrival_stop)">
                        Nastavi naslov kot točko A
                      </vs-dropdown-item>
                      <vs-dropdown-item @click="setAsAddressPointB(step.transit_details.arrival_stop)">
                        Nastavi naslov kot točko B
                      </vs-dropdown-item>
                    </vs-dropdown-menu>
                  </vs-dropdown>

                  ob {{ step.transit_details.arrival_time.text }}
                </div>

                <vs-list>
                  <vs-list-item :title="step.distance.text" subtitle="Razdalja"></vs-list-item>
                  <vs-list-item :title="step.duration.text" subtitle="Čas potovanja"></vs-list-item>
                  <vs-list-header title="Podatki o prevozu" v-if="step.transit_details"></vs-list-header>
                  <vs-list-item :title="step.transit_details.line.name" subtitle="Ime linije"
                                v-if="step.transit_details"></vs-list-item>
                  <vs-list-item :title="step.transit_details.headsign" subtitle="Napis na vozilu"
                                v-if="step.transit_details"></vs-list-item>

                  <div v-if="step.transit_details && step.transit_details.line && step.transit_details.line.agencies">
                    <div v-for="(agency, agencyIndex) in step.transit_details.line.agencies"
                         :key="'agency_' + routeIndex + '_' + agencyIndex">
                      <vs-list-item :title="agency.name" subtitle="Izvajalec prevoza"></vs-list-item>
                    </div>
                  </div>

                </vs-list>

              </div>
            </div>
          </vs-collapse-item>
        </vs-collapse>


        <!--        <div v-for="(leg, legIndex) in route.legs" v-if="route.legs">-->
        <!--          <small>Leg {{legIndex}}</small>-->
        <!--&lt;!&ndash;          {{leg}}&ndash;&gt;-->
        <!--          <div v-for="(step, stepIndex) in leg.steps" v-if="leg.steps">-->
        <!--            <h3>Step {{stepIndex}}</h3>-->
        <!--            <h4 class="text-success">{{step.html_instructions}} <small>({{step.travel_mode}})</small></h4>-->
        <!--            {{step}}-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>


  </div>
</template>
<script>
import Vue from 'vue';
import DirectionsRenderer from "../../../helpers/DirectionsRenderer";

let directionsService = null;
let directionsDisplay = null;
let geoCoder = null;

export default {
  name: 'IntermodalTransport',
  components: {
    DirectionsRenderer,
  },
  props: {
    origin: Object,
    destination: Object,
    departureTime: Number,
    arrivalTime: Number,
  },
  data() {
    return {
      response: null,
      routeOption: null,
      entireRouteInstructions: [],
      routeInstructions: [],
      selectedSteps: {},
      publicTransport: {
        departure: {
          quickSearch: ''
        },
        destination: {
          quickSearch: ''
        }
      },

      center: {lat: 46.0569, lng: 14.5058},
      infoContent: '',
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        pixelOffset: {width: 0, height: -35}
      },
    }
  },
  watch: {
    origin: {
      handler(val, oldVal) {
        const _this = this;
        if (val) {
          // _this.publicTransport.departure = val;
          console.log("val", val);

          if (val.postal_code) {
            _this.publicTransport.departure.addressName = val.address_name ? val.address_name : '';
            _this.publicTransport.departure.country = val.country;
            _this.publicTransport.departure.municipality = val.municipality;
            _this.publicTransport.departure.postalCode = val.postal_code;
            _this.publicTransport.departure.postalTown = val.postal_town;
            _this.publicTransport.departure.quickSearch = val.quick_search;
            _this.publicTransport.departure.route = val.route;
            _this.publicTransport.departure.streetNumber = val.street_number;
          } else {
            _this.publicTransport.departure = val;
          }

        }
      },
      deep: true
    },
    destination: {
      handler(val, oldVal) {
        const _this = this;
        if (val) {
          // _this.publicTransport.destination = val;
          console.log("val", val);

          if (val.postal_code) {
            _this.publicTransport.destination.addressName = val.address_name ? val.address_name : '';
            _this.publicTransport.destination.country = val.country;
            _this.publicTransport.destination.municipality = val.municipality;
            _this.publicTransport.destination.postalCode = val.postal_code;
            _this.publicTransport.destination.postalTown = val.postal_town;
            _this.publicTransport.destination.quickSearch = val.quick_search;
            _this.publicTransport.destination.route = val.route;
            _this.publicTransport.destination.streetNumber = val.street_number;
          } else {
            _this.publicTransport.destination = val;
          }

        }
      },
      deep: true
    },
    routeOption: {
      handler(val, oldVal) {
        const _this = this;
        _this.routeInstructions = [];
        _this.entireRouteInstructions = [];

        _.each(_this.response.routes[val].legs[0].steps, function (_val, idx) {
          let obj = {name: 'step_' + idx + '_route_' + val, value: _val};
          _this.routeInstructions.push(obj);
          _this.entireRouteInstructions.push(obj);
        });
        _this.selectedSteps = {};

        _.each(_this.response.routes[val].legs[0].steps, function (_val, idx) {
          Vue.set(_this.selectedSteps, 'step_' + idx + '_route_' + val, true);
        });
        _this.$forceUpdate();
      }
    },
    selectedSteps: {
      handler(val, oldVal) {
        const _this = this;
        _this.routeInstructions = [];
        console.log("val", val);

        _.each(val, function (stepSelected, idx) {
          if (stepSelected) {

            _.each(_this.entireRouteInstructions, function (_val, _idx) {
              if (_val.name === idx) {
                _this.routeInstructions.push(_val);
              }
            });
          }
        });

        _this.$forceUpdate();
      },
      deep: true
    },
    routeInstructions: {
      handler(val, oldVal) {
        const _this = this;
        console.log("routeInstructions", val);
        _this.$emit('routeInstructions', val);
      },
      deep: true
    }
  },
  mounted() {
    this.publicTransport.departure = this.origin;
    this.publicTransport.destination = this.destination;
  },
  methods: {
    async setAsAddressPointA(stopData) {
      const _this = this;
      _this.$vs.loading();
      console.log("point A data:", stopData);

      _this.publicTransport.departure = {};

      await _this.getAddress(stopData.location, 'pointA');

      setTimeout(() => {
        _this.$vs.loading.close();
      }, 300);

    },
    async setAsAddressPointB(stopData) {
      const _this = this;
      _this.$vs.loading();
      console.log("point B data:", stopData);

      _this.publicTransport.destination = {};

      await _this.getAddress(stopData.location, 'pointB');

      // window.scrollTo(
      //   0,
      //   0, 'smooth'
      // );

      setTimeout(() => {
        _this.$vs.loading.close();
      }, 300);

    },

    async getTrafficData() {
      const _this = this;

      _this.$vs.loading();

      let data = {
        origin: _this.publicTransport.departure.quickSearch,
        destination: _this.publicTransport.destination.quickSearch
      }

      if (_this.departureTime) {
        data.departureTime = _this.departureTime;
      }

      if (_this.arrivalTime) {
        data.arrivalTime = _this.arrivalTime;
      }

      await Vue.prototype.$http.post('https://timetables.sopotniki.org/timetables', data)
        .then((res) => {
          console.log("res.data", res.data);
          _this.response = res.data;
          _this.$vs.loading.close();
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    async getAddress(location, point) {
      const _this = this;

      geoCoder = new google.maps.Geocoder();
      await geoCoder.geocode({'location': location}, function (results, status) {
        if (status === 'OK') {
          if (results[0]) {
            console.log("TOLE", results[0]);
            if (point === 'pointA') {
              _this.getAddressData(results[0], 'departure');
            } else {
              _this.getAddressData(results[0], 'destination');
            }
          } else {
            console.error('Geocode was not successful for the following reason: ' + status);
          }

        } else {
          console.error('Geocode was not successful for the following reason: ' + status);
        }
      });

    },

    getAddressData(addressData, addressSection) {
      const _this = this;

      console.log("addressData", addressData);
      console.log("addressSection", addressSection);

      console.log("_this.publicTransport BEFORE: ", _this.publicTransport);
      document.getElementById(addressSection + "Address").value = addressData.formatted_address;
      _this.publicTransport[addressSection].quickSearch = addressData.formatted_address;

      _.each(addressData.address_components, function (val) {
        if (val.types.includes('route')) {
          _this.publicTransport[addressSection].route = val.long_name;
        } else if (val.types.includes('street_number')) {
          _this.publicTransport[addressSection].streetNumber = val.long_name;
        } else if (val.types.includes('postal_town')) {
          _this.publicTransport[addressSection].postalTown = val.long_name;
        } else if (val.types.includes('postal_code')) {
          _this.publicTransport[addressSection].postalCode = val.long_name;
        } else if (val.types.includes('administrative_area_level_1')) {
          _this.publicTransport[addressSection].municipality = val.long_name;
        } else if (val.types.includes('country')) {
          _this.publicTransport[addressSection].country = val.long_name;
        }
      });

      console.log("_this.publicTransport AFTER: ", _this.publicTransport);

      if (!directionsService) {
        directionsService = new google.maps.DirectionsService;
        directionsDisplay = new google.maps.DirectionsRenderer;
        directionsDisplay.setMap(_this.$refs.map.$mapObject);
      }


      if (addressSection === 'departure') {
        _this.$emit('departureStop', _this.publicTransport[addressSection]);
      } else {
        _this.$emit('arrivalStop', _this.publicTransport[addressSection]);
      }

      _this.setMapsDirections();

      _this.$forceUpdate();
    },

    setMapsDirections() {
      const _this = this;

      directionsService.route({
        origin: _this.publicTransport.departure.quickSearch,
        destination: _this.publicTransport.destination.quickSearch,
        optimizeWaypoints: false,
        travelMode: google.maps.TravelMode.DRIVING
      }, function (response, status) {
        if (status === 'OK') {
          console.log("response --->", response.routes);
          // _this.routeDistanceAB = response.routes[0].legs[0].distance.value;
          // _this.routeDistanceBC = response.routes[0].legs[1].distance.value;
          // _this.routeDistanceTotal = response.routes[0].legs[0].distance.value + response.routes[0].legs[1].distance.value;
          //
          // _this.routeTravelTimeAB = response.routes[0].legs[0].duration.value;
          // _this.routeTravelTimeBC = response.routes[0].legs[1].duration.value;
          // _this.routeTravelTimeTotal = response.routes[0].legs[0].duration.value + response.routes[0].legs[1].duration.value;
          directionsDisplay.setDirections(response);
        } else {
          console.error('Directions request failed due to ' + status);
        }
      });
    },

  }
}
</script>
